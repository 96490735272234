.container--heading {
  width: 100%;
/*height 66vh*/
  overflow: hidden;
  padding-bottom: 5rem;
}
.l--header-spacer {
  height: 100px;
  width: 100%;
}
.container--banner {
  width: 100%;
  min-height: 80rem;
  padding: 13rem 5rem;
  box-sizing: border-box;
  color: var(--text-4);
}
.banner-el--max-width {
  width: 250px;
}
.l--white {
  background-color: #fff;
  color: var(--text-3);
}
.l--light {
  background-color: var(--bg-3);
  color: var(--text-3);
}
.l--dark {
  color: var(--text-1);
}
.gdpr-item-text {
  width: 50vw;
  min-width: 300px;
  margin-left: 4rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
}
.screenshots__item {
  width: 200px;
  height: 150px;
  margin: auto;
  text-align: center;
}
.screenshots__item:hover {
  cursor: zoom-in;
}
.screenshots__item--big {
  width: 800px;
/*height:119px*/
  margin: 2rem;
  text-align: center;
}
.screenshots__item--big:hover {
  cursor: zoom-out;
}
.l--scrollable:hover {
  cursor: ns-resize;
}
.requirements-item {
  width: 40vw;
  min-width: 300px;
  padding-bottom: 4rem;
}
.l--dual_container {
  width: 40vw;
  padding: 5rem 0;
  position: relative;
}
@media only screen and (max-width: 479px) {
.l--dual_container {
    width: 60vw;
}
.container--heading {
    height: unset;
}
.l--header-spacer {
    display: none;
}
}
.l--gdpr-header {
  background-color: var(--c-dark);
  border-radius: 1rem;
  display: inline-block;
  padding: 2rem;
}
.cloud-icon {
  position: absolute;
  top: -16vw;
  left: -10vw;
  color: rgba(255,255,255,0.05);
}
.cloud-icon--font {
  font-size: calc(80vw);
}
.logo {
  width: 50%;
  z-index: 2;
}
.logo-text {
  width: 75%;
  color: var(--c2);
  position: absolute;
  left: calc(12.5%);
  top: 75%;
  text-align: center;
  font-size: 2rem;
  padding: 2rem;
  font-family: 'Work Sans', sans-serif;
}
strong {
  font-weight: bold;
}
h1 {
  font-size: 5.2rem;
  font-family: 'Work Sans', sans-serif;
  text-align: center;
}
.h1--white {
  color: #fff;
}
.color-bar {
  border-top: 2px solid var(--c);
}
.color2-bar {
  border-top: 2px solid var(--c2);
}
.colorw-bar {
  border-top: 2px solid #fff;
}
.colorb-bar {
  border-top: 2px solid var(--text-3);
}
.color {
  color: var(--c);
}
.color2 {
  color: var(--c2);
}
.text-heading {
  font-family: 'Work Sans', sans-serif;
  color: var(--c);
  font-size: 3rem;
}
.cls-1 {
  fill: var(--c2);
}
.cls-2 {
  fill: #c6c6c6;
}
.cls-3 {
  font-size: 103.95px;
  fill: #151515;
  font-family: Myriad Pro;
  font-weight: 700;
  font-style: italic;
}
.cls-1a {
  fill: var(--c2-dark);
}
.cls-1b {
  fill: var(--c);
}
.cls-1c {
  fill: var(--c-dark);
}
.cls-1z {
  fill: var(--c2);
  transition: 0.3s;
}
.cls-1z:hover {
  cursor: pointer;
  fill: var(--c2-dark);
}
#contact {
  height: calc(100vh);
}
.scheme-lingrad {
  background-color: var(--bg-1);
  background-image: linear-gradient(to right, var(--left-gradient), var(--right-gradient));
  color: var(--text-1);
}
.banner-box {
  background: #fff;
  padding: 2rem;
  border: 1px solid var(--white-hover);
  border-radius: 1rem;
/*box-shadow 0px 5px 10px var(--c)*/
  margin: 2rem 0;
}
.l--dist-img {
  width: 100%;
}
.l--company-img {
  height: 46px;
  border-radius: 4px;
  overflow: hidden;
}
.l--quote {
  padding: 2rem;
  margin: 2rem 0;
  margin-top: 5rem;
  width: 80%;
  box-sizing: border-box;
  border-radius: 1rem;
  font-size: 2.1rem;
  font-style: italic;
  text-align: left;
  position: relative;
}
