
.image-bg[data-v-ff84f210] {
  width:100vw;
  height:100vh;
  overflow:hidden;
  position:fixed;
  z-index:-1;
  top:0;
  left:0;
}
.image-size[data-v-ff84f210]{
  width:100%
}

  /* Maximum aspect ratio */
@media (max-aspect-ratio: 1600/837) {
.image-size[data-v-ff84f210] {
    width:unset;
    height: 100%;
}
}
