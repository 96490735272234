
.nav-link[data-v-5848952b] {
  margin:2rem;
  width: 6rem;
  height: 6rem;
  color: var(--text-1);
  border: 1px solid var(--text-1);
  border-radius: 3rem;
  line-height: 6rem;
  text-align:center;
  font-weight: bold;
  text-decoration: unset;
}
.nav-link[data-v-5848952b]:hover{
  background-color: var(--text-1-s);
  color: var(--bg-1);
  text-decoration: unset;
  cursor: pointer;
  transition: background-color 0.2s ease,
              color 0.2s ease;
}
.--z-priority[data-v-5848952b] {
  z-index: 9999;
}
