
.nav-link-w[data-v-20050058] {
    margin:2rem 1rem;
    /*width: 8rem;*/
    height: 6rem;
    color: var(--text-1);
    border: 0px solid var(--text-1-s);
    border-radius: 0rem;
    line-height: 6rem;
    text-align:center;
    font-weight: bold;
    text-decoration: unset;
}
.nav-link-w[data-v-20050058]:hover{
  /*background-color: var(--text-1-s);*/
  color: var(--text-1-s);
  text-decoration: unset;
  cursor: pointer;
  transition: background-color 0.2s ease,
              color 0.2s ease;
}
.add-shadow[data-v-20050058] {
  border-bottom: 1px solid var(--text-1);
  /*box-shadow: 0px 3px 3px 0px var(--text-1);*/
}
